<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="changeText($t('contact_information_reports'))"
                        :isFilter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <header-mobile :title="changeText($t('contact_information_reports'))"
                               :isFilter="false">
                </header-mobile>
            </template>
            <b-tabs content-class="pt-5 position-relative" class="line-tabs" v-model="tabIndex">
                <ValidationObserver ref="form">
                    <b-tab :title="$t('acceptance_data')" active>
                        <b-row class="mt-5">
                            <b-col md="8" sm="6" xs="12">
                                <b-row>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="job" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('now_job')">
                                                <true-false-selectbox v-model="formData.job"
                                                                      :validate-error="errors[0]"
                                                                      :options="trueOrFalseFormat"/>
<!--                                                <b-form-invalid-feedback v-if="errors[0]"/>-->
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>

                                    <!-- FORM -- FACULTY -->
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="faculty_code" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('faculty')">
                                                <faculty-selectbox v-model="formData.faculty_code"
                                                                   :validate-error="errors[0]"></faculty-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>

                                    <!-- FORM -- PROGRAM -->
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="program_code" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('program')">
                                                <program-selectbox v-model="formData.program_code"
                                                                   :faculty_code="formData.faculty_code"
                                                                   :validate-error="errors[0]"></program-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="department_code" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('department')">
                                                <department-selectbox v-model="formData.department_code"
                                                                      :faculty_code="formData.faculty_code"
                                                                      :validate-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="national_id" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('national_id')">
                                                <b-form-input
                                                    v-model="formData.national_id"
                                                    :state="errors[0] ? false:null">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="student_number" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('student_number')">
                                                <b-form-input
                                                    v-model="formData.student_number"
                                                    :state="errors[0] ? false:null">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="nationality_code"
                                                            rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('nationality')">
                                                <country-selectbox valueType="code"
                                                                   v-model="formData.nationality_code"
                                                                   :placeholder="$t('nationality')"
                                                                   :state="errors[0] ? false:null"
                                                                   :validate-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="name" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('name')">
                                                <b-form-input
                                                    v-model="formData.name"
                                                    :state="errors[0] ? false:null">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="surname" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('surname')">
                                                <b-form-input
                                                    v-model="formData.surname"
                                                    :state="errors[0] ? false:null">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="class" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('class')">
                                                <parameter-selectbox v-model="formData.class"
                                                                     code="classes"
                                                                     :validate-error="errors[0]"
                                                />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="have_payment_approval" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('have_payment_approval')">
                                                <true-false-selectbox v-model="formData.payment_status"/>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>


                                </b-row>
                            </b-col>

                            <b-col md="4" sm="6" xs="12">
                                <b-row>
                                    <b-col cols="12">
                                        <ValidationProvider name="student_numbers" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('students')">
                                                <lined-textarea v-model="formData.student_numbers"
                                                                :nowrap="false"
                                                                :disabled="false"
                                                                :styles="{ height: '450px', resize: 'both' }"
                                                                :validateError="errors[0]">
                                                </lined-textarea>
                                                <div class="invalid-feedback d-block" v-if="errors[0]"
                                                     v-html="errors[0]"></div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>


                    </b-tab>
                    <b-tab :title="$t('registration_data')">
                        <b-row class="mt-5">
                            <b-col md="8" sm="6" xs="12">
                                <b-row>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="student_status" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('student_status')">
                                                <parameter-selectbox code="student_statuses"
                                                                     v-model="formData.student_status"
                                                                     :validateError="errors[0]"></parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="program_level" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('program_level')">
                                                <multi-parameter-selectbox
                                                    code="program_levels"
                                                    v-model="formData.program_level"
                                                ></multi-parameter-selectbox>
                                                <b-form-invalid-feedback
                                                    v-if="errors[0]"
                                                    v-html="errors[0]"
                                                ></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>

                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="scholarship_rate" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('scholarship_rate')">
                                                <scholarship-rate-selectbox v-model="formData.scholarship_rate">>
                                                </scholarship-rate-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="registration_type" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('registration_type')">
                                                <parameter-selectbox v-model="formData.registration_type"
                                                                     :validate-error="errors[0]"
                                                                     code="registration_types"></parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="registration_academic_year" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('registration_academic_year')">
                                                <academic-years-selectbox
                                                    v-model="formData.registration_academic_year"></academic-years-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="registration_semester" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('registration_semester')">
                                                <semesters-selectbox
                                                    v-model="formData.registration_semester"></semesters-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="registration_date_between" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('registration_date_between')" class="mb-0">
                                                <v-date-picker
                                                    v-model="formData.registration_date_between"
                                                    is-range
                                                    :locale="'en'"
                                                >
                                                    <template v-slot="{ inputValue, inputEvents }">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-grow-1">
                                                                <b-form-group class="m-0 mr-1">
                                                                    <input :value="inputValue.start"
                                                                           v-on="inputEvents.start"
                                                                           :placeholder="$t('start_date')"
                                                                           class="form-control"
                                                                    />
                                                                </b-form-group>
                                                            </div>
                                                            <div class="flex-grow-1">
                                                                <b-form-group class="m-0 ml-1">
                                                                    <input :value="inputValue.end"
                                                                           v-on="inputEvents.end"
                                                                           :placeholder="$t('end_date')"
                                                                           class="form-control"
                                                                    />
                                                                </b-form-group>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </v-date-picker>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="graduation_academic_year" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('graduation_academic_year')">
                                                <academic-years-selectbox
                                                    v-model="formData.graduation_academic_year"></academic-years-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="graduation_semester" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('graduation_semester')">
                                                <semesters-selectbox
                                                    v-model="formData.graduation_semester"></semesters-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12">
                                        <ValidationProvider name="graduation_date_between" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('graduation_date_between')" class="mb-0">
                                                <v-date-picker v-model="formData.graduation_date_between" is-range locale="en">
                                                    <template v-slot="{ inputValue, inputEvents }">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-grow-1">
                                                                <b-form-group class="m-0 mr-1">
                                                                    <input :value="inputValue.start"
                                                                           v-on="inputEvents.start"
                                                                           :placeholder="$t('start_date')"
                                                                           class="form-control"
                                                                    />
                                                                </b-form-group>
                                                            </div>
                                                            <div class="flex-grow-1">
                                                                <b-form-group class="m-0 ml-1">
                                                                    <input :value="inputValue.end"
                                                                           v-on="inputEvents.end"
                                                                           :placeholder="$t('end_date')"
                                                                           class="form-control"
                                                                    />
                                                                </b-form-group>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </v-date-picker>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="$t('contact_information')">
                        <b-col md="4" sm="12">
                            <ValidationProvider name="city_id" rules="" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('residence_city')">
                                    <city-selectbox v-model="formData.city_id"></city-selectbox>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="4" sm="12">
                            <ValidationProvider name="father_phone" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('fathers_gsm')">
                                    <b-form-input
                                        v-model="formData.father_phone"
                                        :state="errors[0] ? false:null">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="4" sm="12">
                            <ValidationProvider name="mother_phone" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('mother_gsm')">
                                    <b-form-input
                                        v-model="formData.mother_phone"
                                        :state="errors[0] ? false:null">

                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="4" sm="12">
                            <ValidationProvider name="student_phone" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('student_gsm')">
                                    <b-form-input
                                        v-model="formData.student_phone"
                                        :state="errors[0] ? false:null"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>

                    </b-tab>
                    <div class="d-flex mt-2">
                        <b-button variant="primary" @click="create">{{ $t('create_document') }}</b-button>
                    </div>
                </ValidationObserver>
            </b-tabs>
        </app-layout>
    </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout"
import AcademicYearsSelectbox from '@/components/interactive-fields/AcademicYearsSelectbox'
import Header from "@/layouts/AppLayout/Header"
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
import TrueFalseSelectbox from "@/components/interactive-fields/TrueFalseSelectbox"
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox"
import CitySelectbox from "@/components/interactive-fields/CitySelectbox"
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox"
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox"
import LinedTextarea from "@/components/elements/LinedTextarea"
import {ValidationObserver, ValidationProvider} from "vee-validate"
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox"
import ContactService from "@/services/ContactService"
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox"
import MultiParameterSelectbox from "@/components/interactive-fields/MultiParameterSelectbox"
import ScholarshipRateSelectbox from "@/components/interactive-fields/ScholarshipRateSelectbox"
import qs from "qs"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue"

export default {
    components: {
        SemestersSelectbox,
        AcademicYearsSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        TrueFalseSelectbox,
        CountrySelectbox,
        CitySelectbox,
        DepartmentSelectbox,
        ParameterSelectbox,
        FacultySelectbox,
        ScholarshipRateSelectbox,
        ProgramSelectbox,
        LinedTextarea,
        MultiParameterSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t('student_document_create')
        }
    },
    data() {
        return {
            tabIndex: null,
            formData: {
                job: null,
                faculty_code: null,
                program_code: null,
                department_code: null,
                national_id: null,
                student_number: null,
                nationality_code: null,
                name: null,
                surname: null,
                class: null,
                mode_of_study: null,
                payment_status: null,
                student_numbers: '',
                program_level: null,
                scholarship_rate: null,
                registration_type: null,
                registration_academic_year: null,
                registration_semester: null,
                registration_date_between: null,
                graduation_academic_year: null,
                graduation_semester: null,
                graduation_date_between: null,
                city_id: null,
                father_phone: null,
                mother_phone: null,
                student_phone: null
            },
            trueOrFalseFormat: [
                {value: null, text: ''},
                {value: 0, text: this.$t('yes')},
                {value: 1, text: this.$t('no')}
            ],
        }
    },
    methods: {
        async create() {
            const isValid = await this.$refs.form.validate()

            if (isValid) {
                const formData = {
                    params: {...this.formData},
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                if(this.formData.student_numbers == '') {
                    delete formData.params.student_numbers
                }
                else {
                    formData.params.student_numbers = this.formData.student_numbers.split(/\n/)
                }

                if (this.formData.job === 0) {
                    ContactService.create(formData, 0)
                        .then(response => {

                            this._downloadFile(response, this.$t('student_document_create') + '.xlsx')
                        })
                        .catch(e => {
                            this.showErrors(e)
                        })
                    return
                }
                ContactService.create(formData, 1)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    })
                    .catch(e => {
                        this.showErrors(e)
                    })
            }
        },

    }
};
</script>
